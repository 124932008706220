<template>
  <the-wrapper :isSpinner="isSpinner">
    <slot>
      <CtiDescriptionCommon />
      <CtiConnectedList :ctiList="ctiList" />
      <CtiFormRequest :title="title" />
    </slot>
  </the-wrapper>
</template>

<script>
import CtiConnectedList from '@/components/services/cti/CtiConnectedList.vue';
import CtiFormRequest from '@/components/services/cti/CtiFormRequest.vue';
import CtiDescriptionCommon from '@/components/services/cti/CtiDescriptionCommon.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import { getServiceData } from '@/api/common';
import { catchFunction } from '@/additional_helpers/helpers';
import URL from '@/basUrl';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      title: 'Внести изменения',
      ctiList: [],
      isSpinner: true
    };
  },

  components: {
    CtiFormRequest,
    TheWrapper,
    CtiConnectedList,
    CtiDescriptionCommon
  },

  created() {
    getServiceData(URL.CTI_LIST)
      .then((result) => {
        if (result.status) {
          if (result.status === 404) this.title = 'Подключить первую интеграцию';
          this.SHOW_LOADING(false);
          return;
        }
        this.ctiList = result;
        this.SHOW_LOADING(false);
      })
      .catch((e) => catchFunction(e));
  },

  methods: {
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING' })
  }
};
</script>

<style></style>
