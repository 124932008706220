<template>
  <ServicesDescription :isFooter="isFooter">
    <template #title>Мои интеграции</template>
    <template #body>
      <div>
        <info-link @clickLink="isVisible = !isVisible">
          <slot>
            <div class="dflex_nowrap valign-center">
              <span> Объедините возможности телефонии и ваших бизнес-приложений! </span>
              <carret-arrow
                v-model="isVisible"
                :position="isVisible"
                :baseAngle="carretAngle"
              ></carret-arrow>
            </div>
          </slot>
        </info-link>
      </div>
      <b-collapse v-model="isVisible" style="margin-top: 1rem">
        <section v-for="sectionItem in descriptionText" :key="sectionItem.class">
          <h5 class="dflex_nowrap valign-center">
            <span :class="[$style.icon, sectionItem.class]"></span>
            <span :class="$style.sectionTitle">{{ sectionItem.title }}</span>
          </h5>
          <p>
            {{ sectionItem.text }}
          </p>
          <br />
        </section>
        <section>
          <h5>Возможности интеграции телефонии и CRM</h5>
          <text-list :text="possibilities" :square="square"></text-list>
        </section>
      </b-collapse>
    </template>
  </ServicesDescription>
</template>

<script>
import TextList from '@/components/shared/TextList.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import CarretArrow from '@/components/shared/CarretArrow.vue';
import InfoLink from '@/components/shared/InfoLink.vue';

export default {
  data() {
    return {
      isFooter: false,
      isVisible: true,
      carretAngle: 180,
      square: true,
      possibilities: [
        'Персональное приветствие',
        'Всплывающие карточки звонков',
        'Записи разговора в карточке клиента',
        'Автоматическая переадресация звонка клиента на персонального менеджера',
        'Назначение ответственного за лид в зависимости от приема звонка',
        'Создание задачи при пропущенном звонке'
      ],
      descriptionText: [
        {
          class: this.$style.automat,
          title: 'Автоматизация привычной работы',
          text:
            'Сотрудник может совершить звонок в один клик из любого раздела CRM-системы. Во время разговора можно работать со сделками, предложениями и счетами.'
        },
        {
          class: this.$style.quality,
          title: 'Повышение качества обслуживания',
          text:
            'Информация о звонящем клиенте всплывает на экране CRM во время вызова. Входящие звонки автоматически направляются на ответственного или свободного менеджера'
        },
        {
          class: this.$style.control,
          title: 'Контроль и управление звонками',
          text:
            'История и статистика по всем звонкам сотрудников, комментарии клиентов и записи разговоров подгружаются прямо в CRM-систему, интегрированную с телефонией.'
        }
      ]
    };
  },

  components: {
    ServicesDescription,
    TextList,
    CarretArrow,
    InfoLink
  }
};
</script>

<style module>
.icon {
  height: 2rem;
  background: left / cover no-repeat url('../../../assets/img/sprite-1.png');
}

.automat {
  width: 2.75rem;
}

.quality {
  width: 2.5rem;
  background-position-x: 58%;
}

.control {
  width: 2rem;
  background-position-x: 100%;
}

.sectionTitle {
  padding-left: 1rem;
}

.sectionTitle {
  flex-shrink: 10;
}
</style>
