<template>
  <base-service>
    <template #body>
      <base-table
        v-if="ctiList.length && !ctiList.status"
        :items="ctiList"
        :fields="fields"
        :isHeader="false"
      ></base-table>
    </template>
  </base-service>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';
import BaseService from '@/components/shared/BaseService.vue';

export default {
  props: {
    ctiList: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      fields: [
        { key: 'CrmTypeDescription', label: 'CRM' },
        { key: 'CrmDomain', label: 'Домен' }
      ],
      isSpinner: true
    };
  },
  components: {
    BaseTable,
    BaseService
  }
};
</script>

<style></style>
